import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Grid, Button, Icon, Divider, Card, Image } from 'semantic-ui-react'
import { Disqus } from 'gatsby-plugin-disqus'
import { object } from 'prop-types'

import profilePic from 'components/bio/profile-pic.jpg'
import Layout from 'components/Layout'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location}>
        <Grid style={{ margin: 0 }} className="ep-padding-12" centered>
          <Grid.Column mobile={16} tablet={12} computer={10}>
            <Helmet title={`${siteTitle} - ${post.frontmatter.title}`} />
            <div className="ep-space-between">
              <Link to="/">
                <Button basic icon labelPosition="left">
                  <Button.Content visible>Home</Button.Content>
                  <Icon name="home" />
                </Button>
              </Link>
              <Link to="/tags">
                <Button basic icon labelPosition="right">
                  <Button.Content visible>All tags</Button.Content>
                  <Icon name="tag" />
                </Button>
              </Link>
            </div>
            <h1>{post.frontmatter.title}</h1>
            <p>{post.frontmatter.date}</p>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <div className="ep-space-around ep-mb-section ep-mt-section">
              <Card fluid>
                <Card.Content>
                  <Image floated="right" size="mini" rounded src={profilePic} />
                  <Card.Header>Eleftherios Pegiadis</Card.Header>
                  <Card.Meta>Front-end Engineer</Card.Meta>
                  <Card.Description>
                    In the case you <Icon name="heart" color="red" />
                    this post, I want to hear about it in the comments below. In
                    the case that you didn't like it, found it offensive or
                    disagreed with something written in it, again don't hesitate
                    to leave a comment below or contact me at{' '}
                    <strong>
                      <a href="mailto:pegiadis@agileturtles.gr">
                        pegiadis@agileturtles.gr
                      </a>
                    </strong>
                  </Card.Description>
                </Card.Content>
              </Card>
            </div>
            <Divider horizontal>Thanks for reading</Divider>
            <div className="ep-space-between">
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  <Button basic icon labelPosition="left">
                    <Button.Content visible>Previous post</Button.Content>
                    <Icon name="left arrow" />
                  </Button>
                </Link>
              )}

              {next && (
                <Link to={next.fields.slug} rel="next">
                  <Button basic icon labelPosition="right">
                    <Button.Content visible>Next post</Button.Content>
                    <Icon name="right arrow" />
                  </Button>
                </Link>
              )}
            </div>
            <Disqus
              style={{ marginTop: 20 }}
              config={{
                url: this.props.location.href,
                identifier: this.props.location.href,
                title: post.frontmatter.title
              }}
            />
          </Grid.Column>
        </Grid>
      </Layout>
    )
  }
}

export default BlogPostTemplate

BlogPostTemplate.propTypes = {
  location: object
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        path
      }
    }
  }
`
